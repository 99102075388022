import React from 'react'
import {Paragraph, Title, Title4, LeftSection, RightSection, Row} from '../../components/UI'
import cards from '../../images/cards.jpg'
import styled from 'styled-components'
import locationData from '../../data/locations.json'
import {breakpoints} from '../../utils/helper'
import StorePage from '../../components/StorePage'
import programs from '../../data/programs'
import {OutboundLink} from 'gatsby-plugin-gtag';
import vip from '../../images/vip2.jpg';
import Button from '../../components/Button';

const Image = styled.img`
    width: 100%;
    height: auto;
    
    @media ${breakpoints.desktop} {
        width: 620px;
    }
`

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    margin: 0 0 42px;
    
    @media ${breakpoints.tablet} {
        width: 90%;
    }
`

const RedTitle = styled(Title4)`
    color: ${props => props.theme.colors.red};
    white-space: pre-wrap;
`

const ExternalLink = styled(OutboundLink)`
    text-decoration: none;
`

const VIPRow = styled(Row)`
    flex-direction: column-reverse;
    margin-top: 30px;
    img {
        margin-top: 60px;
    }
    
    @media ${breakpoints.tablet} {
        flex-direction: row;
        
        img {
            margin-top: 0;
        }
    }
`

const RSection = styled(RightSection)`
    @media ${breakpoints.desktop} {
        width: 60%;
    }
`


const Castricum = ({location}) => {
    const progs = programs['items'].slice(0, -1)

    return (
        <StorePage data={locationData.locations[2]} location={location} programs={programs.items.slice(0, -1)}>
            <Row>
                <LeftSection>
                    {/*<RedTitle>Tot 25% korting</RedTitle>
                    <Title className="padded">Get UP early!</Title>
                    <StyledParagraph>Wees er vroeg bij, want bij ons krijg je doordeweeks tussen 8:00 en 9:00 uur UP
                        early korting. Je betaalt dan tot maar liefst 25% minder voor je wasprogramma!</StyledParagraph>*/}
                </LeftSection>
                <RightSection>
                    <Image src={cards} alt="Club UP member cards"/>
                </RightSection>
            </Row>
            <VIPRow>
                <LeftSection>
                    <Image src={vip} alt="Club UP member cards"/>
                </LeftSection>
                <RSection>
                    <RedTitle>Car Cosmetics Centre</RedTitle>
                    <Title className="padded">Boek nu</Title>
                    <StyledParagraph>Het UP Car Cosmetics Centre is dé plek om jouw auto optimaal te laten verzorgen.
                        Wij zijn gespecialiseerd in het poetsen, polijsten en reinigen van alle voertuigen.</StyledParagraph>
                    <ExternalLink rel="noopener"
                                  href="https://outlook.office365.com/owa/calendar/IndoorCarwashBeverwijk@indoorcarwash.nl/bookings/"
                                  target="_blank">
                        <Button>Plan je behandeling</Button>
                    </ExternalLink>
                </RSection>
            </VIPRow>
        </StorePage>
    )
}

export default Castricum
